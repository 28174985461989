import { convertEditorStateToHTML } from "braft-convert";
import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "isomorphic-dompurify";

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
export const escapeRegexCharacters = (str: string): string => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const isJSON = (str: string | number): boolean => {
  try {
    JSON.parse(str as string);
  } catch (e) {
    return false;
  }

  return isNaN(str as number); // Also check not a number
};

export const isEmailAddress = (input: string): boolean => {
  const re = /\S+@\S+\.\S+/;

  return re.test(input);
};

export const createMarkup = (html: string): { __html: string } => {
  const sanitizedHtml = DOMPurify.sanitize(html, { ADD_ATTR: ["target"] });

  return {
    __html: sanitizedHtml
  };
};

export const getHtmlFromRaw = (raw: string): string => {
  try {
    const parsedJSON = JSON.parse(raw);
    const contentState = convertFromRaw(parsedJSON);
    const editorState = EditorState.createWithContent(contentState);

    const html = convertEditorStateToHTML(editorState);

    return html;
  } catch (err) {
    console.error(err);

    return "";
  }
};

export const getPlainTextFromRaw = (raw: string): string => {
  let parsedJSON;

  try {
    parsedJSON = JSON.parse(raw);
  } catch (err) {
    console.error(err);

    return "";
  }

  const contentState = convertFromRaw(parsedJSON);
  const editorState = EditorState.createWithContent(contentState);

  const plainText = editorState.getCurrentContent().getPlainText();

  return plainText;
};

export const getCharacterFromAlphabetByIndex = (index: number) => {
  const indexWithLimitHandled = index % 26;

  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];

  return alphabet[indexWithLimitHandled];
};

export const getStringWithCommasRemoved = (str: string): string => {
  return str.replace(/,|;/g, " ");
};

export const removeEmojis = (str: string) => {
  return str.replace(
    /([\uD83C-\uDBFF\uDC00-\uDFFF]+|[\u200D\u2640\u2642\u2695\u267E-\u2699\u26A0-\u26FF])/gu,
    ""
  );
};
